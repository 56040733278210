(function () {
	'use strict';

	angular
		.module('app')
		.factory('trelloConfig', [
			'$sce',
			'utilities',
			'seedcodeCalendar',
			trelloConfig,
		]);

	function trelloConfig($sce, utilities, seedcodeCalendar) {
		return {
			apiConnection: apiConnection,
			config: config,
			fieldMap: fieldMap,
			unusedMap: unusedMap,
			allowHTMLMap: allowHTMLMap,
			hiddenFieldMap: hiddenFieldMap,
			readOnlyFieldMap: readOnlyFieldMap,
			colors: colors,
		};

		function apiConnection() {
			var platform = utilities.getDBKPlatform();
			return {
				clientID: '',
				calendarApiKey: '',
				accessScope: '',
				authIsRedirect:
					platform === 'dbkfm' ||
					platform === 'dbkfmjs' ||
					platform === 'dbkfmwd'
						? true
						: false,
				authRedirectFunction:
					platform === 'dbkfmjs' || platform === 'dbkfmwd'
						? function (authURL, callback) {
								utilities.filemakerExternalOauth(
									platform,
									authURL,
									'code=',
									callback
								);
							}
						: false,
			};
		}

		function config() {
			return {
				id: 11,
				name: 'Trello',
				propertyName: 'trello',
				getApiConnection: apiConnection,
				available:
					seedcodeCalendar.get('settings')?.sourceTypes?.trello,
				editable: true,
				isPrimary: true,
				async: true,
				seperateSchedules: true,
				colorType: 'hex',
				borderColor: 'black',
				color: 'blue',
				textColor: 'white',
				iconPath: `${_CONFIG.DBK_BASEURL}images/trello.png`,
				sourceHelp: $sce.trustAsHtml(
					'There is no required "configuration" for Google Calendars; You may add custom fields or actions to your events using the tabs above.'
				),
				parentSourceHelp: $sce.trustAsHtml(
					'Please select a calendar to the left in order to change that calendar’s settings.'
				),
				fieldMappingHelp: $sce.trustAsHtml(
					"<h4>You can choose the label to appear next to a field when editing an event.</h4>If you don't want a particular field to appear just un-check the show check-box beside the field. If you don't see a checkbox it means that field is required for the calendar to work."
				),
				eventActionsHelp: $sce.trustAsHtml(
					'<h4>Change what happens when users click on, edit, or delete an event.</h4><p>Customize your calendar by altering the editing behavior in DayBack. Create actions that fire when you drag an event or hover over one. Reference event attributes in your action by wrapping your field names in two square brackets like this: [[id]]</p><p>Learn more: <a href="https://docs.dayback.com/article/20-event-actions" target="_blank">Creating Event Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, many of which are event actions you can paste in here. Popular actions include <a href="https://dayback.com/listing/link-related-events/" target="_blank">cascading changes through linked events downstream</a>.</p>'
				),
				customActionsHelp: $sce.trustAsHtml(
					'<h4>Add custom buttons to the event’s popover drawer.</h4><p>Take action right from the calendar by adding your own buttons beside an event. Buttons can interact with other apps, navigate to details about the event, or bring up forms you design.</p><p>Learn more: <a href="https://docs.dayback.com/article/5-custom-actions" target="_blank">Creating Button Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, several of which are buttons you can paste in here. Popular buttons include <a href="https://dayback.com/listing/zoom-meetings-calendar/" target="_blank">scheduling Zoom meetings</a>.</p>'
				),
				hasInstructions: true,
				maxSources: 1,
				showAuthButton: true,
				authButton: `${_CONFIG.DBK_BASEURL}images/authorize-google.png`,
				eventFocus: null,
				refresh: null,
				useISOTimestamp: true,
				useDefaultFieldMap: true,
				customActionsEditable: true,

				settings: {
					//setting: the name of the setting, visible: wether or not the setting is user facing, defaultValue: the default value if any
					id: {
						setting: 'id',
						visible: false,
						defaultValue: new Date().getTime(),
					},
					sourceTypeID: {
						setting: 'sourceTypeID',
						visible: false,
						defaultValue: 3,
					},
					name: {
						setting: 'name',
						visible: true,
						displayValue: 'Calendar Name',
						format: 'name',
						defaultValue: 'New Trello Calendar',
					},
					backgroundColor: {
						setting: 'backgroundColor',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Background Color',
						defaultValue: 'rgba(244, 244, 244, 0.85)',
						format: 'colorPicker',
						helptext:
							'The status color to used for newly created events or events with no status. We recommend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br> \
									<div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br> \
							 ...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
					},
					readOnly: {
						setting: 'readOnly',
						visible: false,
						displayValue: 'Read-Only',
						defaultValue: false,
						format: 'yesno',
					},
					readOnlyNote: {
						setting: 'readOnlyNote',
						visible: true,
						displayValue: 'Read-Only',
						defaultValue: '',
						helptext:
							'For Google calendars, please manage read-only attributes at the calendar and user level in <a href="https://www.google.com/calendar" target="_blank">Google Calendar</a>. Restrictions set there will be respected here.',
						format: 'note',
					},
					allowDayback: {
						setting: 'allowDayback',
						visible: false,
						displayValue: 'Allow defend your time',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'Setting this to false will hide the "defend your time" checkbox when creating new events in this source.',
					},
					allowUnscheduled: {
						setting: 'allowUnscheduled',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Allow unscheduled events',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'When enabled and a boolean unscheduled field is mapped you can mark events as unscheduled and they will not show in the main calendar view but in the unscheduled section instead. <a href="https://docs.dayback.com/article/294-unscheduled-items" target="_blank">Learn more</a>.',
					},
					isUnavailable: {
						setting: 'isUnavailable',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Unavailable',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to true will render any events on this source as unavailable time on the calendar rather than regular events. <a href="https://docs.dayback.com/article/258-availability" target="_blank">Learn more about availability.</a>',
					},
					isMeasureOnly: {
						setting: 'isMeasureOnly',
						visible: true,
						scheduleOnly: true,
						displayValue: 'For Analytics Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from displaying but will allow the data from the events to be used in analytics charts.',
					},
					isMapOnly: {
						setting: 'isMapOnly',
						visible: false,
						scheduleOnly: true,
						displayValue: 'For Map Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from the calendar but will allow the data to be used in maps.',
					},
					fileDateFormat: {
						setting: 'fileDateFormat',
						visible: false,
						displayValue: 'Backend date format',
						defaultValue: 'YYYY-MM-DD',
					},
					isPrimary: {
						setting: 'isPrimary',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default calendar for new items',
						defaultValue: false,
						format: 'primaryCalendar',
					},
					defaultResource: {
						setting: 'defaultResource',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Resource',
						defaultValue: '',
						format: 'defaultResource',
					},
					defaultDrawer: {
						setting: 'defaultDrawer',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default Popover Drawer',
						defaultValue: '',
						format: 'select',
						options: [
							{id: '', label: ''},
							{id: 'dateStart', label: 'Start'},
							{id: 'dateEnd', label: 'End'},
							{id: 'calendar', label: 'Calendar'},
							{id: 'location', label: 'Location'},
							{id: 'resource', label: 'Resource'},
							{id: 'status', label: 'Status'},
							{id: 'customFields', label: 'Custom Fields'},
							{id: 'action', label: 'Button Actions'},
						],
						helptext:
							'Optional. Choose a popover drawer that should be shown automatically when clicking on an event.</br>  <a href="https://docs.dayback.com/article/167-drawers">Learn more about drawers here.</a>',
					},
					disableAllDay: {
						setting: 'disableAllDay',
						visible: false,
						scheduleOnly: true,
						displayValue: 'Disable All Day',
						defaultValue: true,
						format: 'yesno',
						helptext: '',
					},
				},
			};
		}

		function fieldMap() {
			return {
				title: {
					setting: 'title',
					visible: true,
					displayValue: 'Title Display Summary',
					defaultValue: 'Title, Description',
					labelValue: 'Display',
					hideAdvanced: true,
					required: true,
					unused: false,
					helptext:
						'This is the text that appears on the calendar for each event. You can combine several fields here, seperated by a comma. These fields will be seperated by carriage returns in the Schedule views. You can specify which string to substitute for the carriage return in the other views in "Event Styles."' +
						'<p><strong>Standard field names that can be referenced are:</strong>' +
						'<ul>' +
						'<li>Title</li>' +
						'<li>Description</li>' +
						'<li>AllDay</li>' +
						'<li>Start</li>' +
						'<li>End</li>' +
						'<li>Location</li>' +
						'<li>Resource</li>' +
						'<li>Status</li>' +
						'</ul>' +
						'Any custom field available to the calendar may also be referenced using the ID or the field name assigned to that custom field.' +
						'</p>' +
						'<p>' +
						'Styling adjustments can be made with a special "dbk-css" tag. Wrapping fields in this tag will allow inline styling or CSS classes.' +
						'</p>' +
						'<div class="exampleCode">' +
						'&lt;dbk-css style="color: red;"&gt;Title&lt;/dbk-css&gt;, &lt;dbk-css class="large-text"&gt;Description&lt;/dbk-css&gt;' +
						'</div>' +
						'<br />' +
						'<p>You’ll find more instructions on styling events with CSS <a href="https://docs.dayback.com/article/111-event-styles" target="_blank">here</a>.</p>',
				},
				eventID: {
					setting: 'eventID',
					visible: false,
					displayValue: 'Event id',
					defaultValue: 'id',
					required: true,
					unused: false,
					helptext:
						'The primary key or unique ID in your table. For all the fields that follow, use <i>just</i> your field name, not the table and field name. So if your field was called "id" you\'d enter id above, not MyTable::id',
				},
				created: {
					setting: 'created',
					visible: false,
					displayValue: 'Creation Date',
					defaultValue: 'dateLastActivity',
					required: false,
					unused: false,
					helptext:
						'The primary key or unique ID in your table. For all the fields that follow, use <i>just</i> your field name, not the table and field name. So if your field was called "id" you\'d enter id above, not MyTable::id',
				},
				titleEdit: {
					setting: 'titleEdit',
					visible: true,
					displayValue: 'Title',
					defaultValue: 'name',
					labelValue: 'Enter Title',
					required: true,
					unused: false,
					helptext: '',
				},
				description: {
					setting: 'description',
					visible: true,
					displayValue: 'Description',
					defaultValue: 'description',
					labelValue: 'Description',
					required: false,
					unused: false,
					helptext: '',
				},
				description: {
					setting: 'description',
					visible: true,
					displayValue: 'Description',
					defaultValue: 'desc',
					labelValue: 'Description',
					required: false,
					unused: false,
					helptext: '',
				},

				start: {
					setting: 'start',
					visible: true,
					displayValue: 'Start',
					defaultValue: 'start',
					labelValue: 'Start',
					required: true,
					unused: false,
					helptext: '',
				},
				end: {
					setting: 'end',
					visible: true,
					displayValue: 'Due',
					defaultValue: 'due',
					labelValue: 'Due',
					required: true,
					unused: false,
					helptext: '',
				},
				calendar: {
					setting: 'calendar',
					visible: true,
					uiOnly: true,
					displayValue: 'Calendar',
					defaultValue: '',
					labelValue: 'Calendar',
					required: false,
					unused: false,
					helptext: '',
				},
				status: {
					setting: 'status',
					visible: true,
					displayValue: 'Status',
					defaultValue: 'idList',
					labelValue: 'Status',
					required: false,
					unused: false,
					helptext: '',
				},
				resource: {
					setting: 'resource',
					visible: true,
					displayValue: 'Resource',
					defaultValue: 'idMembers',
					labelValue: 'Resource',
					required: false,
					unused: false,
					helptext: '',
				},
				tags: {
					setting: 'tags',
					visible: false,
					displayValue: 'Tags',
					defaultValue: 'tags',
					labelValue: 'Tags',
					required: false,
					unused: false,
					helptext: '',
				},
				unscheduled: {
					setting: 'unscheduled',
					visible: false,
					displayValue: 'Unscheduled',
					defaultValue: 'unscheduled',
					labelValue: 'Unscheduled',
					required: false,
					unused: false,
					helptext:
						'The field that holds a boolean value to indicate if the event should be treated as unscheduled. <a href="https://docs.dayback.com/article/294-unscheduled-items" target="_blank">Learn more</a>.',
				},
				eventURL: {
					setting: 'eventURL',
					visible: false,
					displayValue: 'External Event URL',
					defaultValue: 'url',
					labelValue: 'Event URL',
					required: false,
					unused: false,
					helptext:
						'An external url to show the event on a different platform.',
				},
				sortPriority: {
					setting: 'sortPriority',
					visible: false,
					displayValue: 'Sort Priority',
					defaultValue: '',
					labelValue: 'Sort Priority',
					required: false,
					unused: false,
					helptext: '',
				},
				attendees: {
					setting: 'attendees',
					visible: false,
					displayValue: 'Attendees',
					labelValue: 'Attendees',
					defaultValue: 'attendees',
					required: false,
					unused: false,
					helptext: 'List of attendees if any exist.',
				},
				additionalDayBackData: {
					setting: 'additionalDayBackData',
					visible: false,
					displayValue: 'additionalDayBackData',
					defaultValue: '{}',
					labelValue: 'additionalDayBackData',
					required: false,
					unused: false,
					helptext: '',
				},
				customFields: {
					setting: 'customFields',
					visible: true,
					uiOnly: true,
					displayValue: 'Custom Fields',
					defaultValue: '',
					labelValue: 'Custom Fields',
					required: false,
					unused: false,
					helptext: '',
				},
			};
		}

		function unusedMap() {
			return {
				allDay: true,
				contactID: true,
				contactName: true,
				projectID: true,
				projectName: true,
				parentListID: true,
				parentListName: true,
				done: true,
			};
		}

		function allowHTMLMap() {
			return {};
		}

		function hiddenFieldMap() {
			return {};
		}

		function readOnlyFieldMap() {
			return {};
		}

		function colors() {
			return {
				1: {
					background: '#a4bdfc',
					foreground: '#1d1d1d',
				},
				2: {
					background: '#7ae7bf',
					foreground: '#1d1d1d',
				},
				3: {
					background: '#dbadff',
					foreground: '#1d1d1d',
				},
				4: {
					background: '#ff887c',
					foreground: '#1d1d1d',
				},
				5: {
					background: '#fbd75b',
					foreground: '#1d1d1d',
				},
				6: {
					background: '#ffb878',
					foreground: '#1d1d1d',
				},
				7: {
					background: '#46d6db',
					foreground: '#1d1d1d',
				},
				8: {
					background: '#e1e1e1',
					foreground: '#1d1d1d',
				},
				9: {
					background: '#5484ed',
					foreground: '#1d1d1d',
				},
				10: {
					background: '#51b749',
					foreground: '#1d1d1d',
				},
				11: {
					background: '#dc2127',
					foreground: '#1d1d1d',
				},
			};
		}
	}
})();
